<template>
  <div>
    <!----------------------------------------- 主列表 --------------------------------------->
    <!---------------页眉相关 ----------------->
    <Row style="margin-top: 10px">
      <Col span="12" style="padding: 0 5px">
        <Card>
          <div style="
          display: flex;
          justify-content: space-between;
          width: 100%;
          margin-bottom: 10px;
        ">
            <div style="display: flex">
              <div style="padding-left: 20px;font-size: 18px;font-weight: bold">
                <span>养老院坐标信息</span>
              </div>
            </div>
            <div>
              <Button
                      type="primary"
                      @click="getFunNl"
                      icon="md-search"
                      style="margin-right: 5px"
              >查询</Button
              >
            </div>
          </div>

          <!---------------页眉相关 END----------------->
          <!---------------表格相关 ----------------->
          <Table
                  ref="orderManAgeTable"
                  border
                  :columns="dataMain.oldNlColumns"
                  :data="dataMain.orderManNlData"
                  size="small"
                  :height="tableHeight"
                  :width="tableWidth"
                  highlight-row
                  tooltip-theme="light"
                  :loading="tableJgLoading"
          >
            <template slot="value5"  slot-scope="props">
              <Input v-model="props.row.value5"
                     :active-change="false"
                     @on-blur="oldManSave('养老院坐标信息',props)"
              />
            </template>
            <template slot="value2"  slot-scope="props">
              <Input v-model="props.row.value2"
                     :active-change="false"
                     @on-blur="oldManSave('养老院坐标信息',props)"
              />
            </template>
            <template slot="value3"  slot-scope="props">
              <Input v-model="props.row.value3"
                     :active-change="false"
                     @on-blur="oldManSave('养老院坐标信息',props)"
              />
            </template>
            <template slot="value4"  slot-scope="props">
              <Input v-model="props.row.value4"
                     :active-change="false"
                     @on-blur="oldManSave('养老院坐标信息',props)"
              />
            </template>
            <template slot="value6"  slot-scope="props">
              <Input v-model="props.row.value6"
                     :active-change="false"
                     @on-blur="oldManSave('养老院坐标信息',props)"
              />
            </template>
            <template slot="jd"  slot-scope="props">
              <Input v-model="props.row.jd"
                     :active-change="false"
                     @on-blur="oldManSave('养老院坐标信息',props)"
              />
            </template>
            <template slot="wd"  slot-scope="props">
              <Input v-model="props.row.wd"
                     :active-change="false"
                     @on-blur="oldManSave('养老院坐标信息',props)"
              />
            </template>
          </Table>
          <!---------------表格相关 END----------------->
        </Card>

      </Col>
      <Col span="12" style="padding: 0 5px">
        <Card>
          <div style="
          display: flex;
          justify-content: space-between;
          width: 100%;
          margin-bottom: 10px;
        ">
            <div style="display: flex">
              <div style="padding-left: 20px;font-size: 18px;font-weight: bold">
                <span>护理站坐标信息</span>
              </div>
            </div>
            <div>
              <Button
                      type="primary"
                      @click="getFunFwryJg"
                      icon="md-search"
                      style="margin-right: 5px"
              >查询</Button
              >
            </div>
          </div>

          <!---------------页眉相关 END----------------->
          <!---------------表格相关 ----------------->
          <Table
                  ref="orderManAgeTable"
                  border
                  :columns="dataMain.rjzlColumns"
                  :data="dataMain.rjzlData"
                  size="small"
                  :height="tableHeight"
                  :width="tableWidth"
                  highlight-row
                  tooltip-theme="light"
                  :loading="rjzlTableLoading"
          >
            <template slot="value2"  slot-scope="props">
              <Input v-model="props.row.value2"
                     :active-change="false"
                     @on-blur="oldManSave('护理站坐标信息',props)"
              />
            </template>
            <template slot="value3"  slot-scope="props">
              <Input v-model="props.row.value3"
                     :active-change="false"
                     @on-blur="oldManSave('护理站坐标信息',props)"
              />
            </template>
            <template slot="value4"  slot-scope="props">
              <Input v-model="props.row.value4"
                     :active-change="false"
                     @on-blur="oldManSave('护理站坐标信息',props)"
              />
            </template>
            <template slot="value5"  slot-scope="props">
              <Input v-model="props.row.value5"
                     :active-change="false"
                     @on-blur="oldManSave('护理站坐标信息',props)"
              />
            </template>
            <template slot="value6"  slot-scope="props">
              <Input v-model="props.row.value6"
                     :active-change="false"
                     @on-blur="oldManSave('护理站坐标信息',props)"
              />
            </template>
            <template slot="jd"  slot-scope="props">
              <Input v-model="props.row.jd"
                     :active-change="false"
                     @on-blur="oldManSave('护理站坐标信息',props)"
              />
            </template>
            <template slot="wd"  slot-scope="props">
              <Input v-model="props.row.wd"
                     :active-change="false"
                     @on-blur="oldManSave('护理站坐标信息',props)"
              />
            </template>
          </Table>
          <!---------------表格相关 END----------------->
        </Card>

      </Col>
    </Row>
    <Row style="margin-top: 10px">
      <Col span="6" style="padding: 0 5px">
        <Card>
          <div
                  style="
          display: flex;
          justify-content: space-between;
          width: 100%;
          margin-bottom: 10px;
        "
          >
            <div style="display: flex">
              <div style="padding-left: 20px;font-size: 18px;font-weight: bold">
                <span>工单人次</span>
              </div>
            </div>
            <div>
              <Button
                      type="primary"
                      @click="getFunGd"
                      icon="md-search"
                      style="margin-right: 5px"
              >查询</Button
              >
            </div>
          </div>
          <!---------------页眉相关 END----------------->
          <!---------------表格相关 ----------------->
          <Table
                  ref="orderManAgeTable"
                  border
                  :data="dataMain.fwxmsfData"
                  :columns="dataMain.fwxmsfColumns"
                  size="small"
                  :height="tableHeight"
                  :width="tableWidth"
                  highlight-row
                  tooltip-theme="light"
                  :loading="FwxmsftableLoading"
          >
            <template slot="number"  slot-scope="props">
              <Input v-model="props.row.number"
                     :active-change="false"
                     @on-blur="oldManSave('工单人次',props)"
              />
            </template>
            <template slot="sm"  slot-scope="props">
              <Input v-model="props.row.sm"
                     :active-change="false"
                     @on-blur="oldManSave('工单人次',props)"
              />
            </template>
            <template slot="jg"  slot-scope="props">
              <Input v-model="props.row.jg"
                     :active-change="false"
                     @on-blur="oldManSave('工单人次',props)"
              />
            </template>
          </Table>
          <!---------------表格相关 END----------------->
        </Card>

      </Col>
      <Col span="6" style="padding: 0 5px">
        <Card>
          <div
                  style="
          display: flex;
          justify-content: space-between;
          width: 100%;
          margin-bottom: 10px;
        "
          >
            <div style="display: flex">
              <div style="padding-left: 20px;font-size: 18px;font-weight: bold">
                <span>服务人次信息</span>
              </div>
            </div>
            <div>
              <Button
                      type="primary"
                      @click="getFunFwxx"
                      icon="md-search"
                      style="margin-right: 5px"
              >查询</Button
              >
            </div>
          </div>
          <!---------------页眉相关 END----------------->
          <!---------------表格相关 ----------------->
          <Table
                  ref="orderManAgeTable"
                  border
                  :data="dataMain.qxfwjgData"
                  :columns="dataMain.fuxxColumns"
                  size="small"
                  :height="tableHeight"
                  :width="tableWidth"
                  highlight-row
                  tooltip-theme="light"
                  :loading="FwxxLoading"
          >
            <template slot="number"  slot-scope="props">
              <Input v-model="props.row.number"
                     :active-change="false"
                     @on-blur="oldManSave('服务人次信息',props)"
              />
            </template>
            <template slot="bfb"  slot-scope="props">
              <Input v-model="props.row.bfb"
                     :active-change="false"
                     @on-blur="oldManSave('服务人次信息',props)"
              />
            </template>
          </Table>
          <!---------------表格相关 END----------------->
        </Card>

      </Col>
      <Col span="12" style="padding: 0 5px">
        <Card>
          <div
                  style="
          display: flex;
          justify-content: space-between;
          width: 100%;
          margin-bottom: 10px;
        "
          >
            <div style="display: flex">
              <div style="padding-left: 20px;font-size: 18px;font-weight: bold">
                <span>服务趋势/年</span>
              </div>
            </div>
            <div>
              <Button
                      type="primary"
                      @click="getFunjgyfwdxbd"
                      icon="md-search"
                      style="margin-right: 5px"
              >查询</Button
              >
            </div>
          </div>

          <!---------------页眉相关 END----------------->
          <!---------------表格相关 ----------------->
          <Table
                  ref="orderManAgeTable"
                  border
                  :columns="dataMain.jgyfwdxbdColumns"
                  :data="dataMain.jgyfwdxbdData"
                  size="small"
                  :height="tableHeight"
                  :width="tableWidth"
                  highlight-row
                  tooltip-theme="light"
                  :loading="AgeLoading"
          >
            <template slot="value1"  slot-scope="props">
              <Input v-model="props.row.value1"
                     :active-change="false"
                     @on-blur="oldManSave('服务趋势/年',props)"
              />
            </template>
            <template slot="value2"  slot-scope="props">
              <Input v-model="props.row.value2"
                     :active-change="false"
                     @on-blur="oldManSave('服务趋势/年',props)"
              />
            </template>
            <template slot="value3"  slot-scope="props">
              <Input v-model="props.row.value3"
                     :active-change="false"
                     @on-blur="oldManSave('服务趋势/年',props)"
              />
            </template>
            <template slot="value4"  slot-scope="props">
              <Input v-model="props.row.value4"
                     :active-change="false"
                     @on-blur="oldManSave('服务趋势/年',props)"
              />
            </template>
            <template slot="value5"  slot-scope="props">
              <Input v-model="props.row.value5"
                     :active-change="false"
                     @on-blur="oldManSave('服务趋势/年',props)"
              />
            </template>
            <template slot="value6"  slot-scope="props">
              <Input v-model="props.row.value6"
                     :active-change="false"
                     @on-blur="oldManSave('服务趋势/年',props)"
              />
            </template>
            <template slot="value7"  slot-scope="props">
              <Input v-model="props.row.value7"
                     :active-change="false"
                     @on-blur="oldManSave('服务趋势/年',props)"
              />
            </template>
            <template slot="value8"  slot-scope="props">
              <Input v-model="props.row.value8"
                     :active-change="false"
                     @on-blur="oldManSave('服务趋势/年',props)"
              />
            </template>
          </Table>
          <!---------------表格相关 END----------------->
        </Card>

      </Col>
    </Row>
    <!----------------------------------------- 主列表 END--------------------------------------->
  </div>
</template>
<!--大屏数据维护-->
<script>
  import {
    getOneDtList,
    updateTwoRightYear,
    updateoneYlXx,
    updateoneHlXx,
    updateOneYlZb,
    getOneRightOneListPage,
    updateoneHlZb,
    updateoneSqXx,
    updateoneSqZb,
    deleteOneRightOne,
    insertOneRightOne,
    getTwoTopBtList,
    getTwoTopList,
    updateTwoTopBt,
    updateTwoTop,
    getTwoRightYearList,
  } from "./dataMaintenance";

//todo
export default {
  name: "dataMaintenance",
  data() {
    return {
      //--------------------------主页面表格用--------------------------
      tableHeight: 0,
      tableWidth: "",
      validation_data: {
        name: [
          { required: true, message: "政府信息不能为空", trigger: "blur" },
        ],
      },
      dataMain: {
        jgyfwdxbdData:[],
        orderManNlData:[],
        rjzlData:[],
        fwryRsData:[],//服务人员结构信息人社
        fwJgRsData:[],//服务人员结构信息机构
        qxfwjgData:[],//区县服务结构信息
        fwxmsfData:[],//服务项目收费信息
        jgyfwdxbdColumns: [
          { title: "序号", type: "index", width: 70, align: "center" },
          { title: "趋势类型", key: "name", minWidth: 70, align: "center" },
          {title: "2021人次/万", slot: 'value1', align: "center", minWidth: 70,},
          {title: "2020人次/万", slot: 'value2', align: "center", minWidth: 70,},
          {title: "2019人次/万", slot: 'value3', align: "center", minWidth: 70,},
          {title: "2018人次/万", slot: 'value4', align: "center", minWidth: 70,},
          {title: "2017人次/万", slot: 'value5', align: "center", minWidth: 70,},
          {title: "2016人次/万", slot: 'value6', align: "center", minWidth: 70,},
          {title: "2015人次/万", slot: 'value7', align: "center", minWidth: 70,},
          {title: "2014人次/万", slot: 'value8', align: "center", minWidth: 70,},
        ],
        oldNlColumns: [
          { title: "序号", type: "index", width: 70, align: "center" },
          { title: "养老院名称", key: "name", minWidth: 120, align: "center" },
          {title: "床位总数", slot: 'value2', align: "center", minWidth: 70,},
          {title: "入住人数", slot: 'value3', align: "center", minWidth: 70,},
          {title: "绑定服务老人数", slot: 'value4', align: "center", minWidth: 70,},
          {title: "服务人员数", slot: 'value5', align: "center", minWidth: 80,},
          {title: "机构等级", slot: 'value6', align: "center", minWidth: 80,},
          {title: "经度", slot: 'jd', align: "center", minWidth: 90,},
          {title: "纬度", slot: 'wd', align: "center", minWidth: 90,},
        ],
        rjzlColumns: [
          { title: "序号", type: "index", width: 70, align: "center" },
          { title: "护理站名称", key: "name", minWidth: 120, align: "center" },
          {title: "床位总数", slot: 'value2', align: "center", minWidth: 70,},
          {title: "入住人数", slot: 'value3', align: "center", minWidth: 70,},
          {title: "服务人员数", slot: 'value4', align: "center", minWidth: 80,},
          {title: "绑定服务老人数", slot: 'value5', align: "center", minWidth: 80,},
          {title: "日均服务人数", slot: 'value6', align: "center", minWidth: 80,},
          {title: "经度", slot: 'jd', align: "center", minWidth: 90,},
          {title: "纬度", slot: 'wd', align: "center", minWidth: 90,},
        ],
        fwryRsColumns: [
          { title: "序号", type: "index", width: 70, align: "center" },
          { title: "公示信息", slot: "name", align: "center" },
          { title: "删除", slot: "action",width: 65, align: "center" },
        ],
        fwxmsfColumns: [
          { title: "序号", type: "index", width: 70, align: "center" },
          { title: "工单类型", key: "name", minWidth: 90, align: "center" },
          { title: "工单人次/日", slot: "number", minWidth: 120, align: "center" },
          {title: "机构比例/%", slot: 'jg', align: "center", minWidth: 80,},
          {title: "上门比例/%", slot: 'sm', align: "center", minWidth: 80,},
        ],
        fuxxColumns: [
          { title: "序号", type: "index", width: 70, align: "center" },
          { title: "服务类型", key: "name", minWidth: 90, align: "center" },
          { title: "服务人次/年", slot: "number", minWidth: 120, align: "center" },
          {title: "去年同比/%", slot: 'bfb', align: "center", minWidth: 80,},
        ],

      },
      //--------------------------主页面表格用 END--------------------------
      AgeLoading: false,
      tableJgLoading: false,
      RstableLoading: false,
      rjzlTableLoading: false,
      FwxxLoading: false,
      FwxmsftableLoading: false,
      showModal: false,
      show: true,
      addOrUpdateMain:{
        name:'',
      }
    };
  },
  methods: {
    //居家服务趋势/年
    getFunjgyfwdxbd(){
      this.AgeLoading = true
      getTwoRightYearList().then(res=>{
        this.AgeLoading = false
        this.dataMain.jgyfwdxbdData = res;
      })
    },
    //养老院坐标信息
    getFunNl(){
      this.tableJgLoading = true
      getOneDtList().then(res=>{
        let list = res.oneYlXxVO;
        let listzb = res.oneYlZbVO;
        this.dataMain.orderManNlData = [];
        for(let i=0;i<list.length;i++){
          for(let j=0;j<listzb.length;j++){
            if(list[i].name == listzb[j].name){
              list[i].jd = listzb[j].jd
              list[i].wd = listzb[j].wd
            }
          }
        }
        this.dataMain.orderManNlData = list;
        this.tableJgLoading = false
      })
    },
    //政府信息公示窗口
    getFunRs(){
      this.RstableLoading=true
      getOneRightOneListPage().then(res=>{
        this.RstableLoading=false
        this.dataMain.fwryRsData = res;
      })
    },
    //日间照料坐标信息
    getFunFwryJg(){
      this.rjzlTableLoading = true;
      getOneDtList().then(res=>{
        let list = res.oneHlXxVO;
        let listzb = res.oneHlZbVO;
        this.dataMain.rjzlData = [];
        for(let i=0;i<list.length;i++){
          for(let j=0;j<listzb.length;j++){
            if(list[i].name == listzb[j].name){
              list[i].jd = listzb[j].jd
              list[i].wd = listzb[j].wd
            }
          }
        }
        this.dataMain.rjzlData = list;
        this.rjzlTableLoading = false
      })
    },
    //社区坐标信息
    getFunGd(){
      this.FwxmsftableLoading = true
      getTwoTopBtList().then(res=>{
        this.dataMain.fwxmsfData = res;
        this.FwxmsftableLoading = false
      })
    },
    //服务人次信息
    getFunFwxx(){
      this.FwxxLoading = true
      getTwoTopList().then(res=>{
        this.dataMain.qxfwjgData = res;
        this.FwxxLoading = false
      })
    },
    oldManSave(type,props) {
      let data =  props.row;
      if(type == '工单人次'){
        updateTwoTopBt(data).then(res =>{
          if(res.code == 0){
            this.$Message.success("修改成功");
            this.getFunGd()
          }else{
            this.$Message.error("修改失败");
          }
        })
      }
      if(type == '服务人次信息'){
        updateTwoTop(data).then(res =>{
          if(res.code == 0){
            this.$Message.success("修改成功");
            this.getFunFwxx()
          }else{
            this.$Message.error("修改失败");
          }
        })
      }
      if(type == '养老院坐标信息'){
        updateoneYlXx(data).then(res =>{
          if(res.code == 0){
            updateOneYlZb(data).then(res =>{
              if(res.code == 0){
                this.$Message.success("修改成功");
                this.getFunNl()
              }else{
                this.$Message.error("修改失败");
              }
            })
          }else{
            this.$Message.error("修改失败");
          }
        })
      }
      if(type == '服务趋势/年'){
        updateTwoRightYear(data).then(res =>{
          if(res.code == 0){
            this.$Message.success("修改成功");
            this.getFunjgyfwdxbd()
          }else{
            this.$Message.error("修改失败");
          }
        })
      }
      if(type == '护理站坐标信息'){
        updateoneHlXx(data).then(res =>{
          if(res.code == 0){
            updateoneHlZb(data).then(res =>{
              if(res.code == 0){
                this.$Message.success("修改成功");
                this.getFunFwryJg()
              }else{
                this.$Message.error("修改失败");
              }
            })
          }else{
            this.$Message.error("修改失败");
          }
        })
      }
      if(type == '社区坐标信息'){
        updateoneSqXx(data).then(res =>{
          if(res.code == 0){
            updateoneSqZb(data).then(res =>{
              if(res.code == 0){
                this.$Message.success("修改成功");
                this.getFunGd()
              }else{
                this.$Message.error("修改失败");
              }
            })
          }else{
            this.$Message.error("修改失败");
          }
        })
      }
    },
    //政府信息公示窗口
    addZfxx(){
      this.showModal = true;
      this.addOrUpdateMain.name ="";
      this.$refs["showModal"].resetFields();
    },
    //删除
    delZf(obj){
      deleteOneRightOne(obj).then(res=>{
        if(res.code == 0){
          this.$Message.success("删除成功");
          this.getFunRs()
        }else{
          this.$Message.error("删除失败");
        }
      })

    },
    closeMod(){
      this.showModal = false;
      this.$refs["showModal"].resetFields();
      this.addOrUpdateMain.name ="";
    },
    add(){
      this.$refs["showModal"].validate((valid) => {
          if (valid) {
            insertOneRightOne(this.addOrUpdateMain).then(res=>{
              if(res.code == 0){
                this.$Message.success("新增成功");
                this.getFunRs()
                this.closeMod()
              }else{
                this.$Message.error("新增失败");
              }
            })
          }
      })
    }
  },
  //todo
  //---------------------初始化加载 ----------------------
  mounted() {
    this.tableHeight = window.innerHeight * 0.5 - 90;
    console.log(this.tableHeight)
    window.onresize = () => {
      return (() => {
        this.tableHeight = window.innerHeight * 0.5 - 90;
      })();
    };

    this.getFunNl();
    this.getFunFwryJg();
    this.getFunGd();
    this.getFunFwxx();
    this.getFunjgyfwdxbd();
    this.getFunRs();
  },
  //---------------------初始化加载 END----------------------
};
</script>
<style>
  .ivu-icon{
    left: 25px !important;
  }
</style>